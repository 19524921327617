
import Vue from "vue";

import UploadArea from "@/components/UploadArea/UploadArea.vue";
import * as selectOptions from "@/helpers/selectOptions";
import { downloadImportFileTemplate } from "@/helpers";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

interface IData {
  message: string;
  error: string;
  value: string;
  uploadData: Record<string, any>;
  tierOptions: any;
}

export default Vue.extend({
  name: "addNewUpload",
  components: {
    UploadArea,
    CustomAlert
  },
  data(): IData {
    return {
      message: "",
      error: "",
      value: "",
      uploadData: {
        tier: ""
      },
      tierOptions: selectOptions.tiers
    };
  },
  methods: {
    onFileUploadSuccess(result: any) {
      this.$appNotifySuccess("File Upload Success");
      this.$emit("upload-success", result);
      this.$emit("close");
    },
    onFileUploadError(err: any) {
      this.error = err?.message;
      this.$emit("error", err);
    },
    countiesTemplateDownloadHandler() {
      downloadImportFileTemplate("ppc");
    }
  },
  computed: {
    showUploader(): boolean {
      return this.uploadData.tier.length > 0;
    }
  }
});
